var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.noninteractive",
          modifiers: { hover: true, noninteractive: true }
        }
      ],
      staticClass: "tabe__bookmark",
      class: { "tabe__bookmark--selected": _vm.value },
      attrs: {
        type: "button",
        title: _vm.value ? "Bookmarked" : "Bookmark this " + _vm.name
      },
      on: { click: _vm.toggle }
    },
    [
      _c(
        "svg",
        {
          staticStyle: { "enable-background": "new 0 0 940.688 940.688" },
          attrs: {
            version: "1.1",
            id: "Capa_1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            x: "0px",
            y: "0px",
            width: "940.688px",
            height: "940.688px",
            viewBox: "0 0 940.688 940.688",
            "xml:space": "preserve"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "bookmark-icon",
              d:
                "M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8\n\t\t\t\t\t\t\t\t\t\t\t\t\tc-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601\n\t\t\t\t\t\t\t\t\t\t\t\t\tc45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }